import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import SectionNav from "../components/newComponents/sectionNav";
import SectionHead from "../components/newComponents/sectionHead";
import Card from "../components/newComponents/card";
import GeneralSection from "../components/newComponents/generalSection";

export const query = graphql`
  
  query WhatWeDoProjectsEntryQuery($slug: String!) {
    
    entry: craftWhatWeDoWhatWeDoEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      relatedAreasOfExpertiseFocusAreas {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          id
        }
      }
      relatedAreasOfExpertise {
        ... on Craft_areaOfExpertise_areaOfExpertise_Entry {
          id
        }
      }
      relatedProjects {
        ... on Craft_projects_projects_Entry {
          displayTitle
          title
          summary
          uri
          featureImage {
            ...AssetFragment
          }
        }
      }
      relatedFocusArea {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          id
        }
      }
      relatedTeamMembers {
        ... on Craft_staff_staff_Entry {
          slug
        }
      }
    }

    relatedFocusAreaProjects: allCraftProjectsProjectsEntry(filter: {relatedFocusArea: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
        displayTitle
        title
        summary
        uri
        postDate
        featureImage {
          ...AssetFragment
        }
      }
    }

    relatedAreaOfExpertiseProjects: allCraftProjectsProjectsEntry(filter: {relatedAreaOfExpertise: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
        displayTitle
        title
        summary
        uri
        postDate
        featureImage {
          ...AssetFragment
        }
      }
    }
  }
`;

const WhatWeDoProjectsEntry = ({ data, pageContext }) => {

  const projects = data.relatedFocusAreaProjects.nodes.concat(data.relatedAreaOfExpertiseProjects.nodes)
  let uniqueProjects = [];
  let projectIds = [];
    
  for(let project of projects) {
    
    if(projectIds.indexOf(project.id) === -1) {

      uniqueProjects.push(project);
      projectIds.push(project.id);
    }
  }

  uniqueProjects.sort(function(a, b) {
    return new Date(b.postDate).getTime() - new Date(a.postDate).getTime();
  });

  return (
    <Layout pageContext={pageContext}>
      <Seo title={data.entry.title} />
      <div className="main">
        <SectionNav data={data} base={`/what-we-do/${pageContext.slug}`} currentUri={`/what-we-do/${pageContext.slug}/projects`}/>
        <GeneralSection className="section-overview" style={{paddingBottom: 0}}>
          <SectionHead title={data.entry.title} subtitle={"All projects"}/>
        </GeneralSection>
        {uniqueProjects.length > 0 ?
          <GeneralSection className="section-listing section-listing--secondary js-section-listing" style={{paddingTop: 0}}>
            <div className="boxes swiper-container js-slider-boxes-projects">
              <ul>
                {uniqueProjects.map((item, i) => (
                  <Card key={`what-we-do-projects-entry-${i}`} image={item.featureImage[0]} title={item.title} subtitle={item.summary} href={`/${item.uri}`} color={1}/>
                ))}
              </ul>
            </div>
          </GeneralSection>
          :
          <></>
        }
      </div>
    </Layout>
  );
};

export default WhatWeDoProjectsEntry;